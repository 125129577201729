import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import { ContainerRow } from '@components/styled';
import CollapsePanel from '@shared/ui/layout/CollapsePanel';
import { BodyMedium } from '@components/styled/Typography';
import IconButton from '@shared/ui/buttons/Button/IconButton';
const AccordionContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: props.theme.spacing_sizes.xs * 2,
    paddingBottom: props.theme.spacing_sizes.xs * 2,
    borderBottom: '1px solid #EFF1F3',
}));
const TitleContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
const CollapseButton = styled(IconButton, { shouldForwardProp: propName => propName !== 'isOpen' })(props => ({
    transition: 'background-color 250ms',
    '& svg': {
        transition: 'transform 250ms',
        transform: props.isOpen ? 'rotate(45deg)' : 'rotate(0deg)',
    },
}));
const SectionTitle = styled(BodyMedium)(() => ({
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.28px',
    lineHeight: '26px',
}));
const PanelInner = styled.div(() => ({
    maxWidth: 1200,
    lineHeight: '26px',
}));
const AccordionSection = ({ isOpen, onOpen = undefined, title = undefined, children, destroyOnHide = false, }) => {
    const handleSectionOpen = () => {
        if (onOpen) {
            onOpen();
        }
    };
    return (_jsxs(AccordionContainer, { children: [_jsxs(TitleContainer, { onClick: handleSectionOpen, children: [title ? _jsx(SectionTitle, { children: title }) : null, _jsx(CollapseButton, { variant: 'tinted', color: 'primary-light', size: 'large', IconComponent: PlusIcon, isOpen: isOpen })] }), _jsx(CollapsePanel, { isOpen: isOpen, destroyOnHide: destroyOnHide, children: _jsx(PanelInner, { children: children }) })] }));
};
export default AccordionSection;
