import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import styled from '@emotion/styled';
import AccordionSection from '@shared/ui/layout/AccordionSection';
const AccordionContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
const Accordion = ({ onChange = undefined, list, destroyOnHide = false, allowMultipleOpen = false, }) => {
    const [openedSections, setOpenedSections] = useState(() => {
        const defaultOpenedSections = {};
        list.forEach((listItem) => {
            if (listItem === null || listItem === void 0 ? void 0 : listItem.isOpen) {
                defaultOpenedSections[listItem.sectionKey] = true;
            }
            else {
                defaultOpenedSections[listItem.sectionKey] = false;
            }
        });
        return defaultOpenedSections;
    });
    const handleSectionOpen = (sectionKey) => {
        setOpenedSections((prev) => {
            const prevState = prev[sectionKey] || false;
            if (allowMultipleOpen) {
                const newState = Object.assign(Object.assign({}, prev), { [sectionKey]: !prevState });
                if (onChange)
                    onChange(newState);
                return newState;
            }
            const newState = { [sectionKey]: !prevState };
            if (onChange)
                onChange(newState);
            return newState;
        });
    };
    return (_jsx(AccordionContainer, { children: list.map(({ title, children, sectionKey }) => (_jsx(AccordionSection, { title: title, onOpen: () => { handleSectionOpen(sectionKey); }, isOpen: openedSections[sectionKey] || false, destroyOnHide: destroyOnHide, children: children }, sectionKey))) }));
};
export default Accordion;
